import firebase from "gatsby-plugin-firebase";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Card, Dialog, Switch } from "@material-ui/core";
import { Link } from "gatsby";
import HomeAppBar from "../components/HomeAppBar";
import axios from "axios";
 

export default class Divisions extends React.Component {

    importStuff = () =>{
        // var header= {
        //     "User-Agent":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/78.0.3904.108 Safari/537.36",
        //     host: "www.lapingpong.com",
        //     Accept:"text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
        //     origin:"http://www.lapingpong.com"
        // }
        axios.post("http://192.168.1.21:8080/importseason").then((data)=>{console.log(data)})
    }

    render(){
       
        return (
            <div>
                <HomeAppBar title={""} />
                <Button onClick={this.importStuff}>Import</Button>
            </div>
        )
    }
}